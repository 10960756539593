import React from 'react'
import abg from '../images/abg.jpg'
import about from '../images/about1.jpg'
const Aboutus = () => {
    return (
        <>
            <section id="aboutus" className="pt-10 text-gray-800 body-font" style={{ height:"100vh",background:'#101010',}}>
                <h1
                    data-aos="fade-up"
                    className="
          title-font
          sm:text-4xl
          text-3xl

          mt-20
          text-center
          -mb-10
          font-medium
          text-white
        "
                >
                    Who we are
                </h1>
                <div
                    className="
          container
          whobg
          mx-auto
          flex
          px-5
          py-24
          md:flex-row
          flex-col
          items-center
        "
                    // style={{ backgroundImage: `url(${bg})`, backgroundSize: "contain" }}
                >
                    <div className="lg:max-w-lg lg:w-full -mt-4  md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img
                            data-aos="fade-up-right"
                            className="object-cover who-img object-center rounded"
                            alt="hero"
                            src={`${about}`}
                        />
                    </div>
                    <div
                        data-aos="fade-left"
                        className="
            lg:flex-grow
            md:w-1/2
            lg:pl-24
            md:pl-16
            md:pr-4
            
            flex flex-col
            md:items-start md:text-left
            items-center
            text-center
          "
                    >
                        <p className="mb-8 pb-4 whoweare   text-white text-justify text-lg leading-relaxed">
                        We are a group of engineers and designers highly motivated and committed to provide responsive, quality services to our clients. We provide design services to meet our clients needs in structural, piping, mechanical, electrical and civil engineering. We will work on smallest to most significant projects from developing concept design, engineering design, 3D modeling, single line drawings, fabrication drawings, bill of materials and installation drawings. Our team uses industry leading latest softwares to provide efficient solutions to our clients. Once we say we "can" do something, we do it with a passion.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutus
