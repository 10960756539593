import React from 'react'

import { Link } from 'react-router-dom'
const nav = () => {
    return (
        <>
            <header data-aos="fade-down" className="header">
                <Link to="/" className="header__logo"
                ><img src={process.env.PUBLIC_URL + '/static/images/MagnusLogo.PNG'} width="156" alt=""
                    /></Link>

                <ion-icon
                    name="menu-outline"
                    class="header__toggle"
                    id="nav-toggle"
                ></ion-icon>

                <nav className="nav" id="nav-menu">
                    <div className="nav__content bd-grid">
                        <ion-icon
                            name="close-outline"
                            class="nav__close"
                            id="nav-close"
                        ></ion-icon>

                        <div>
                            <div className="logo">
                                <Link to="/">
                                    <img
                                        src={process.env.PUBLIC_URL + '/static/images/MagnusLogo.PNG'}
                                        width="236"
                                        alt=""
                                    />
                                </Link>
                            </div>

                            <div></div>
                        </div>

                        <div className="nav__menu">
                            <ul className="nav__list" style={{ fontSize: "18px" }}>
                                <li className="nav__item">
                                    <a href="/#aboutus" id="hvr" class="nav__link">About</a>
                                </li>
                                <li className="nav__item">
                                    <a href="/#service" id="hvr" class="nav__link">Services</a>
                                </li>
                                <li className="nav__item">
                                    <a href="/#projects" id="hvr" class="nav__link">Projects</a>
                                </li>
                                <li className="nav__item">
                                    <a href="/#testim" id="hvr" class="nav__link">Testimonials</a>
                                </li>
                                <li id="contact" class="nav__item "><a href="/#contact-us" id="hvr" class="nav__link">Contact us</a></li>
                            </ul>
                           
                        </div>
<div className='flex flex-col -mt-1 extranav '>
<div class="employe ">
<a href="" id="" >Login</a>
</div>
                        <div id="contact-info">
                        <div className="links">
                        <a
                                href="https://www.facebook.com/pages/category/Engineering-Service/Magnus-Designers-Inc-2020132491371219/"
                                className="orange-border"
                                target="_blank"
                            >
                                <div>
                                    <span></span>
                                    <span></span>
                                    
                                    
                                    <span>
                                        <i className="fa fa-facebook-f"></i>
                                    </span>
                                </div>
                            </a>    
                        <a
                                href="https://www.linkedin.com/company/magnus-designers?trk=public_profile_topcard-current-company"
                                target="_blank"
                            >
                                <div>
                                    <span></span>
                                    <span></span>
                                    

                                    <span>
                                        <i className="fa fa-linkedin"></i>
                                    </span>
                                </div>
                            </a>

                            
                        </div>
                    </div>
                    </div>
                    </div>
                </nav>
            </header>
        </ >
    )
}

export default nav
