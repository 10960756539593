import React from 'react'

import bg from '../images/testbg.jpg'

const Testimonials = () => {
    return (
        <div>
        <section class="testim" id="testim" style={{
            paddingTop:'70px',
            backgroundImage: `url(${bg}), linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .9))`,
            backgroundBlendMode: 'overlay',
        }} >
       
        <div data-aos="fade-right" class= " wrap bg-white"  >
        <h2 data-aos="fade-right" class="  text-center test-text text-3xl md:text-3xl mt-6" style={{fontWeight: '500'}}> What our Customers Say</h2>

            <span id="right-arrow" class="arrow right fa fa-chevron-right"></span>
            <span id="left-arrow" class="arrow left fa fa-chevron-left"></span>

            <ul class="dots" id="testim-dots">
                <li class="dot active"></li>
                <li class="dot"></li>
                <li class="dot"></li>
            </ul>

            <div class="cont" id="testim-content">

                <div class="active">
                    <div class="img"></div>
                    <h2 class="person">Greg Craddock <span class="profession">-President, Craddock Enterprises LLC</span> </h2>
                    <p>" It was a good experience working with Magnus Designers. Communication was excellent. The drawings appear to be accurate and functional. The overall readability and aesthetics may not be 100%, but didn't affect anything except my
                        picky personality. Overall, it was easy to work with Magnus Designers and they are flexible. I would recommend them to anyone who needs a solid detailed drawings who can adapt to your needs fairly easily."</p>
                </div>

                <div>
                    <div class="img"></div>
                    <h2 class="person">Alicia Tomlin <span class="profession">-Vice President, Gilmon Fox General Contractors</span> </h2>
                    <p>" Great communication and very responsive! Completed the job on time and did very good work. Would definitely use Magnus designers on our future projects. "</p>
                </div>

                <div>
                    <div class="img"></div>
                    <h2 class="person">Timothy Kross <span class="profession">-Operations Manager, Cavalier Engineering</span> </h2>
                    <p>" We use Magnus Designers for all our design needs. As the previous jobs, they have exceeded our expectation. Always delivers as promised, detail oriented and good communication."</p>
                </div>


            </div>

        </div>
    </section>
        </div>
    )
}

export default Testimonials
