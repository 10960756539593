import React from 'react'
import Nav from './Nav'
import Projects from './AllProjectsC'
import Footer from './Footer'
const AllProjects = () => {
    return (
        <>
            <Nav />
            <Projects />
            <Footer />
        </>
    )
}

export default AllProjects
