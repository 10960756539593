import React from 'react'
import logo from '../images/transparent.png'
const Footer = () => {
    return (
        <div className='bg-gray-100 -mb-4'>
        <div class="container footer   mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <nav class="flex lg:w-2/5 flex-wrap text-black items-center text-base md:ml-auto" style={{fontFamily: 'manrope',fontSize: 'large',fontWeight: '500',}}>
            <a href="#aboutus" class="mr-5 hover:text-blue-900">About us</a>
            <a href="#service" class="mr-5 hover:text-blue-900">Services</a>
            <a href="#project" class="mr-5 hover:text-blue-900">Projects</a>
            <a href="#testim" class="hover:text-blue-900">Testimonials</a>
        </nav>
        <a class="flex footerimg order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0">
            <img  src={`${logo}`} alt="" /></a>
        <div class="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
            <div class="social-container">
                <ul class="social-icons">
              
                    <li><a href="https://www.facebook.com/pages/category/Engineering-Service/Magnus-Designers-Inc-2020132491371219/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/magnus-designers?trk=public_profile_topcard-current-company" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    
                </ul>
            </div>
        </div>
    </div>
    <h4 class="text-center text-m mb-4">Magnus Desingers INC 2016 | © Copy rights reserved</h4>
        </div>
    )
}

export default Footer
