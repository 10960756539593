
import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import bridge from '../images/bridge.jpg'
import stairs from '../images/Stairs.jpg'
import slide from '../images/slide.jpg'
import { Link } from 'react-router-dom'

const Projects = ({featured}) => {
    const [projectsData, setProjectData] = useState(null)
    const [index1, setIndex1] = useState(0)
    useEffect(() => {

        if (featured) {
            sanityClient
                .fetch(
                    `*[_type == "projects" && featured == true]{
            title,
            slug,
            description,
            mainImage{
               asset->{
                   _id,
                   url
               },
               alt
           },
           featured,
           images,
           
      }`,
                )
                .then((data) => {
                    // console.log(data)
                    setProjectData(data)
                    // itemsToSend.push(...data);

                })
                .catch(console.error)
        } else {
            sanityClient
                .fetch(
                    `*[_type == "projects"]{
        title,
        slug,
        description,
        mainImage{
           asset->{
               _id,
               url
           },
           alt
       },
       featured,
       images
  }`,
                )
                .then((data) => {
                    // console.log(data)
                    setProjectData(data)
                    // itemsToSend.push(...data);

                })
                .catch(console.error)
        }

    }, [])

    const action = (page, range, items) => {
        console.log(`📄 Page: ${page}, 🌀 Items: `, items);
        // Update State
        setProjectData(items);
    };
    return (
        <div>
        <div id="projects" class="pt-10 text-gray-800 body-font">
        <h1 data-aos="fade-up"  class="title-font text-center sm:text-4xl text-3xl  mt-10  font-medium " style={{color: '#000339',marginBottom:'5%' }}>Featured Projects</h1>
        {projectsData && projectsData.map((project, index) => {
            if (index % 2 === 0) {
                return (
                    <>
                    <div class="container mx-auto flex px-5 py-26 md:flex-row flex-col items-left">
                    <div data-aos="fade-right" class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-left text-left">
                        <h1 class="title-font sm:text-3xl text-2xl mb-1 font-medium " style={{color: '#000339'}}>{project.title} </h1>
                        <p class="mb-6 text-lg leading-relaxed text-justify">{project.description}</p>
                        <div class="flex ">
                          <Link to={'/projects/' + project.slug.current}>  <button class="inline-flex text-black projectknow  border-2 py-2 px-6 focus:outline-none  hover:text-white  text-lg">Know more</button></Link>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img class="object-cover object-center rounded" alt="hero" src={project.mainImage.asset.url}/>
                    </div>
                </div>
                    </>
                )
            } else {
                return (
                    <>
                    <div class="container mt-16 mx-auto flex px-5 py-6 md:flex-row-reverse   flex-col items-left">
           
                    <div data-aos="fade-left" data-aos-delay="500" class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0  text-left">
                        <h1 class="title-font sm:text-3xl text-2xl mb-1 font-medium " style={{color: '#000339'}}>{project.title}
                        </h1>
                        <p class="mb-6 text-lg text-justify leading-relaxed">{project.description}</p>
                        <div class="flex ">
                        <Link to={'/projects/' + project.slug.current}>  <button class="inline-flex text-black projectknow  border-2 py-2 px-6 focus:outline-none  hover:text-white rounded text-lg">Know more</button></Link>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-delay="500" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img class="object-cover object-center rounded" alt="hero" src={project.mainImage.asset.url} />
                </div>
                </div>
                    </>
                )
            }

        })}
        
       
      
       
        <div data-aos="fade-up" class="flex justify-center mt-10 mb-16">
         <Link to="/allprojects">   <button class="inline-flex  moreproj py-2 px-6 focus:outline-none  hover:text-white  text-lg">View all projects</button></Link>
        </div>
    </div>

        </div>
    )
}

export default Projects
