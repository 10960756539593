import React from 'react'
import c1 from '../images/c1.png'
import c2 from '../images/c2.png'
import c3 from '../images/c3.png'
import c4 from '../images/c4.png'
import c5 from '../images/c5.png'
import bg from '../images/clientbg.jpg'

const Clients = () => {
    return (
        <div>
        <div style={{
        backgroundImage: `url(${bg}), linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .9))`,
        backgroundBlendMode: 'overlay',
    }}>
        <h1 data-aos="fade-up" class="text-center text-4xl text-white pt-6">Our Clients</h1>
        <div data-aos="fade-up" class="wrapper">

            <div class="slider">
                <div class="slide">
                    <img src={`${c1}`}/>
                    <img src={`${c2}`} />
                    <img src={`${c3}`} />
                    <img src={`${c4}`} />
                    <img src={`${c5}`} />
                    <img src={`${c1}`} />
                    <img src={`${c2}`} />
                    <img src={`${c3}`} />
                    <img src={`${c4}`} />
                </div>
            </div>
        </div>

    </div>   
        </div>
    )
}

export default Clients
