import React from 'react'
import Mission from '../images/missionbg.png'
import mbg from '../images/mission.png'

const Ourmission = () => {
    return (
        <div className="mission">
                <div class="text-black body-font " style={{
                    backgroundImage: `url(${Mission})`,
                    backgroundSize: 'cover',height:"100vh"
            }}>
        <h1 data-aos="fade-up" class="title-font ourmission sm:text-4xl text-3xl text-center pt-6 md:pt-16 mb-20 md:mb-0   font-medium text-gray-900">Our mission
        </h1>
                 <div class="container mx-auto flex md:px-5 px-0  py-6  md:flex-row flex-col items-center">
                    <div data-aos="fade-up-right" class="lg:flex-grow md:w-1/2   flex flex-col md:items-start md:text-left  items-center text-center">

                <p class="mb-8 mission-text  -mt-20 md:mr-4 mr-4 leading-relaxed text-lg text-justify" >The success of our clients is extremely important to us. Our mission is to deliver professional work, timely response and accurate deliverables to our clients. We strive to meet our client needs and beat the deadlines. We believe that communication and transparency are key for good relationship and that make us unique.</p>

                 </div>
                 <div data-aos="fade-up-left" class="missionimg md:w-1/3 w-2/3">
                <img class="object-cover object-center " alt="hero" src={`${mbg}`} />
            </div>
            </div>
            </div>
        </div>
    )
}

export default Ourmission
