import React from 'react'
import contactbg from '../images/contactbg.jpg'
import contact from '../images/contact.jpg'

import { InlineWidget } from "react-calendly";

const Contactus = () => {
    return (
        <div >
        <h1 data-aos="fade-right"  id="contact-us" class="pt-0 md:pt-36 mb-6 text-4xl text-center " > Get in Touch with us.</h1>
        <div data-aos="fade-right"  class="flex  contact md:flex-row mx-auto flex-col pb-10  mb-16" style={{backgroundImage:`url(${contactbg})`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',opacity:'.9'}}>
            <div class="md:w-1/2 w-full officed" style={{marginTop: '42px',marginLeft:'7%', marginRight: '-10%',}}>
                <div class="container px-5 md:py-24 py-6 mx-auto flex">
                    <div class="lg:w-1/2 details md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-10 mx-auto w-full mt-10 md:mt-28 relative z-10 shadow-md">
                        <span class="lnr lnr-earth mb-2"></span>
                        <h5>Office Location:</h5>
                        <h2>13501 Katy Freeway,</h2>
                        <h2>Suite#1560,</h2>
                        <h2 class="mb-6">Houston, Texas 77079</h2>
                        <span class="lnr lnr-history mb-2"></span>
                        <h2>Operation Hours:</h2>
                        <h2>Monday-Friday</h2>
                        <h2 class="mb-6">09:00 AM - 5:00 PM</h2>
    
                        <span class="lnr lnr-envelope mb-2"></span>
                        <h2>Contact-us</h2>
                        <h2 class="mb-8">+1 281-971-7080</h2>
    
                    </div>
                </div>
            </div>
            <div id="tabs" class="tabs text-black ">
                <div class="tab-header">
                    <div class="active">
                        Schedule a meet
    
                    </div>
                    <div>
                        Send us a message
                    </div>
                </div>
                <div class="tab-indicator"></div>
                <div class="tab-body">
    
                    <div class="active">
                        <div class="calendly" style={{maxWidth:'496px',height:'560px',}} >
                        <InlineWidget url="https://calendly.com/magnusdesignersinc/30min"  /><script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
                        
                        </div>
                    </div>
                    <div>
                        <div class="containr">
                            <form action="" id="cockcrow" name="submit-to-google-sheet" >
                                <label for="fname">Name:</label>
                                <input type="text" id="name" name="name" placeholder="Your name.." />
    
                                <label for="email">Email:</label>
                                <input type="text" id="email" name="email" placeholder="Your email address.." />
                                <label for="number">Number:</label>
                                <input type="number" id="number" name="number" placeholder="Your phone number.." />
                <label for="place">Country:</label>
                                <input type="text" id="place" name="place" placeholder="your country" />
                                <label for="subject">Message:</label>
                                <textarea id="subject" name="event_type" placeholder="your message.." style={{height:'80px'}}></textarea>
                                <div class="g-recaptcha"  data-sitekey="6LdrMqYcAAAAAP8pZYbl7iGkvSpzGietqdKHYQIi"></div>
                                <br/>
                                <input class="submit" type="submit" value="Submit" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Contactus
