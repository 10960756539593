import React from 'react'
import Aboutus from './Aboutus'
import LandingPage from './LandingPage'
import Nav from './Nav'
import Ourmission from './Ourmission'
import Whatwedo from './Whatwedo'
import CoreValues from './CoreValues'
import Clients from './Clients'
import Projects from './Projects'
import Testimonials from './Testimonials'
import Contactus from './Contactus'
import Footer from './Footer'
const Home = () => {
    return (
        <>
            <Nav />
            <LandingPage />
            <Aboutus />
            <Ourmission />
            <Whatwedo />
            <CoreValues />
            <Clients />
            <Projects featured={true} />
            <Testimonials />
            <Contactus />
            <Footer />
        </>
    )
}

export default Home
