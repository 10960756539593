import React from 'react'
import core from '../images/corevalues.jpg'

const CoreValues = () => {
    return (
        <div>
        <div class="text-gray-600 body-font md:mb-0 -mb-80" style={{  height:"100vh",}}>
        <div class="core-values container mx-auto flex px-5 py-26   items-center justify-center flex-col">
            <div data-aos="fade-up" class="text-center lg:w-2/3 w-full">
                <h1 class="title-font sm:text-4xl text-3xl mb-10 font-medium text-gray-900">Core Values</h1>

            </div>
            <img data-aos="fade-up" data-aos-delay="200" class="lg:w-1/2 mb-10 object-cover object-center rounded" alt="hero" src={`${core}`} />

        </div>
    </div>
        </div>
    )
}

export default CoreValues
