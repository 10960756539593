import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import Projects from './components/Projects'
import Services from './components/Services'
import Structural from './components/StructuralDesign'
import PipeDesign from './components/PipeDesign'
import Error from './components/Error'
import AllProjects from './components/AllPorjects';
import Civil from './components/CivilDesign';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Mechnical from './components/Mechnical';
import ProjectP from './components/Projectpage';
import ReactGA from 'react-ga';
import ScrollToTop from "./ScrollToTop";
import { useEffect } from 'react';
import Projectpage from './components/Projectpage';

function App() {
  
  useEffect(() => {
    ReactGA.initialize('UA-210077389-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
     }, [])


  AOS.init({ duration: 1000 })
  return (
    <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route path="/"  component={Home} exact  />
        <Route path="/projects/:slug" component={Projectpage} />
        <Route path="/pipe" component={PipeDesign} />
        <Route path="/allprojects" component={AllProjects} />
        <Route path="/structural" component={Structural} />
        <Route path="/civil" component={Civil} />
        <Route path="/mechnical" component={Mechnical} />
        <Route path="/project" component={ProjectP} />
        <Route component={Error} />
      </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
