
import structure from '../images/structure.jpg'
import s1 from '../images/s1.jpg'
import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import  Loader  from './Loader'
import { useParams } from 'react-router-dom'


import { Link } from 'react-router-dom'

const StructuralContent = () => {
  const [projectData, setProjectData] = useState(null)
 
  useEffect(() => {
      sanityClient
          .fetch(
              `*[_type == "projects" && category == "structural-design" ]{
                title,
                slug,
                description,
                mainImage{
                   asset->{
                       _id,
                       url
                   },
                   alt
               },
               
               
    }`,
          )
          .then((data) => {
              console.log("data :" + data)
              setProjectData(data)
           
          })
          .catch(console.error)

   
  }, [])

if (!projectData) return <><Loader /></>

    return (
        <div>
        <section data-aos="fade-up" class=" text-white body-font" style={{marginTop: '0px !important',color: 'white !important',backgroundImage: `url(${structure}), linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0,.75))`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundBlendMode: 'overlay'}}>
        <div class="  flex px-5 py-36  flex-col items-center">
            <section class="text-white body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center mt-10 w-full mb-12">
                        <h1 class="md:text-6xl text-4xl font-medium title-font mb-4 ">Structural Design</h1>

                    </div>

                </div>
            </section>
        </div>
    </section>
    <section id="aboutus" class=" text-gray-800 body-font">
       
        <div class="container whobg mx-auto flex px-5 py-24 md:flex-row flex-col items-center" >

            <div class="md:w-1/3 w-2/3 mb-10 mx-auto md:mb-0">
                <img data-aos="fade-up-right" class=" who-img object-center rounded" alt="hero" src={`${s1}`} />
            </div>
            <div data-aos="fade-left" class="flex md:w-2/3 lg:pl-8 md:pl-6  flex-col md:items-start md:text-left  text-left">

                <p class="mb-8 whoweare text-black text-lg " >Structural design team at Magnus Designers utilize industry leading softwares to design and detail the structures. Whether it is a Metro Station, Reconditioning 18th Century Building, Farm Shed, Platforms at Offshore Jackup Rig or a Modular Classroom our highly skilled team has experience and expertise to apply efficient solution to the project.

<br/> <br/>
                    The structural projects that Magnus Designers work on, will be completed in compliance with standards, while meeting client's requirements.                    
                    Our following structural design services help our clients simplify their project coordination, improve delivery schedules and increase overall project quality.</p>

            </div>
        </div>
    </section>    <section>
        <div class=" mx-auto md:flex-col sm:py-16 mt-0  py-16 mb-6 lg:flex-row" style={{backgroundImage: `url(${structure}), linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0,.75))`,backgroundSize: 'cover',backgroundRepeat: 'no-repeat',backgroundBlendMode: 'overlay'}}>
            <div class="container text-white  mx-auto " >

                <div class="md:flex sm:text-center sm:object-center md:ml-20 ml-4 flex-wrap">
                    <div class="xl:w-1/3 lg:w-1/2  py-6 ">
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Structural Analysis</h2>
                        
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Foundation Calculations</h2>
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Bolted Connections Design</h2>


                    </div>
                    <div class="xl:w-1/3 lg:w-1/2   py-6 ">
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Welded Connections Design</h2>
                        
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Fabrication Cut Sheet Drawings</h2>
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Shop Assembly Drawings</h2>


                    </div>
                    <div class="xl:w-1/3 lg:w-1/2   py-6 ">
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Erection Drawings</h2>
                        
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Material Lists</h2>
                        <h2 class="text-xl md:text-xl text-left font-medium title-font mb-8">• Bolt Lists</h2>


                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <section class="text-gray-600 body-font">
        <div class="container px-5 py-14 mx-auto">
          <div class="flex flex-col text-center w-full mb-12">
            <h1 class="md:text-2xl text-2xl font-medium title-font mb-0 text-gray-900">Look at some of the Magnus Designers structural projects here.</h1>
        
          </div>
          
          <div class="flex flex-wrap -m-4">
          {projectData && projectData.map((project, index) => {
            return (
          <div class="md:w-1/3 w-full p-4">

           
              <div class="flex relative ">
                <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src={project.mainImage.asset.url} />
                <div class="px-8 py-28 relative z-10 w-full  bg-white opacity-0 hover:opacity-80">
                  
                  <h1 class="title-font text-lg text-center font-medium text-gray-900 mb-3">{project.title}</h1>
                </div>
              </div>


            </div>
            ) })}
          </div>
        </div>
      </section>
      <div className="text-center flex flex-col">
<h2 className="mb-5"><a href="/#contact-us" className="text-blue-900" > Contact us</a> for any questions or queries.</h2>
</div>
        </div>
    )
}

export default StructuralContent
