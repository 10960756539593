import React, { useEffect, useState } from 'react'
import bridge from '../images/bridge.jpg'
import stairs from '../images/Stairs.jpg'
import slide from '../images/slide.jpg'
import sanityClient from '../client.js'
import { Link } from 'react-router-dom'


const AllProjectsC = () => {

    const [projectsData, setProjectsData] = useState(null)
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "projects"]{
            title,
            slug,
            description,
            mainImage{
               asset->{
                   _id,
                   url
               },
               alt
           }
      }`,
            )
            .then((data) => {
                // console.log(data)
                setProjectsData(data)
            })
            .catch(console.error)
    }, [])
    if (!projectsData) return <>Loading....</>
    return (
        <div>
            <div id="project" class="pt-10 text-gray-800 body-font">
                <h1 data-aos="fade-up" id="projects" class="title-font text-center sm:text-4xl text-3xl  mt-16 font-medium " style={{ color: '#000339', marginBottom: '1px' }}>Our Projects</h1>

                {projectsData && projectsData.map((project, index) => {
                    if (index % 2 === 0) {
                        return (
                            <>

                                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                                    <div data-aos="fade-right" class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                                        <h1 class="title-font sm:text-3xl text-2xl mb-1 font-medium " style={{ color: '#000339' }}>{project.title}
                                        </h1>
                                        <p class="mb-6 leading-relaxed">{project.description}</p>
                                        <div class="flex justify-center">
                                            <Link to={'/projects/' + project.slug.current}><button class="inline-flex text-black projectknow  border-2 py-2 px-6 focus:outline-none  hover:text-white  text-lg">Know more</button></Link>
                                        </div>
                                    </div>
                                    <div data-aos="fade-left" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                                        <img class="object-cover object-center rounded" alt="hero" src={project.mainImage.asset.url} />
                                    </div>
                                </div>
                            </>
                        )
                    } else {
                        return (
                            <>
                                
                            <div class="container mt-16 mx-auto flex px-5 py-6 md:flex-row-reverse   flex-col items-left">
           
                            <div data-aos="fade-left" data-aos-delay="500" class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0  text-left">
                                <h1 class="title-font sm:text-3xl text-2xl mb-1 font-medium " style={{color: '#000339'}}>{project.title}
                                </h1>
                                <p class="mb-6 text-lg text-justify leading-relaxed">{project.description}</p>
                                <div class="flex ">
                                <Link to={'/projects/' + project.slug.current}>  <button class="inline-flex text-black projectknow  border-2 py-2 px-6 focus:outline-none  hover:text-white rounded text-lg">Know more</button></Link>
                                </div>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="500" class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                            <img class="object-cover object-center rounded" alt="hero" src={project.mainImage.asset.url} />
                        </div>
                        </div>
                            </>
                        )
                    }
                })}
            </div>

        </div>
    )
}

export default AllProjectsC
