
import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import pipe from '../images/bridge.jpg'
import p1 from '../images/Pic-2.png'
import p2 from '../images/Pic-4.png'

import imageUrlBuilder from '@sanity/image-url'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import p3 from '../images/Pic-3.png'
import Nav from './Nav'
import Projects from './AllProjectsC'
import Footer from './Footer'
import  Loader  from './Loader'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}
const Projectpage = () => {
  const [projectData, setProjectData] = useState(null)
    const { slug } = useParams()
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "projects" && slug.current == "${slug}" ]{
                  title,
                  slug,
                  description,
                  mainImage{
                     asset->{
                         _id,
                         url
                     },
                     alt
                 },
                 featured,
                 images,
                 category
                 
      }`,
            )
            .then((data) => {
                console.log("data :" + data[0])
                setProjectData(data[0])
             
            })
            .catch(console.error)

     
    }, [slug])

  if (!projectData) return <><Loader /></>
    return (
        <>
            <Nav />
           <div>
           <section class="text-gray-700 body-font">
           <div class="container mx-auto flex px-5 py-24 mt-10 md:flex-row flex-col items-center">
             <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
               <img class="object-cover object-center rounded" alt="hero"  src={projectData.mainImage.asset.url} />
             </div>
             <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
               <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{projectData.title}</h1>
               <p class="mb-8 leading-relaxed">{projectData.description}</p>
               
             </div>
           </div>
         </section>
           


         <section class="text-gray-600 body-font">
         <div class="container px-5 py-24 mx-auto flex flex-wrap">
           <div class="flex w-full mb-20 flex-wrap">
             <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">Images of Project</h1>
             
           </div>
           <div class="flex flex-wrap md:-m-2 -m-1">
             <div class="flex flex-wrap w-full  ">
               <div class="md:p-2 p-1 w-1/3">
                 <img alt="gallery" class="w-full object-cover h-full object-center block" src={urlFor(projectData.images[0]).url()}/>
               </div>
               <div class="md:p-2 p-1 w-1/3">
                 <img alt="gallery" class="w-full object-cover h-full object-center block" src={urlFor(projectData.images[1]).url()}/>
               </div>
               <div class="md:p-2 p-1 w-1/3">
                 <img alt="gallery" class="w-full h-full object-cover object-center block" src={urlFor(projectData.images[2]).url()}/>
               </div>
             </div>
            
           </div>
         </div>
       </section>
   
      
   <div className="text-center flex flex-col">
   <h2 className="mb-5"><a href="/#contact-us" className="text-blue-900" > Contact us</a> for any questions or queries.</h2>
   </div>
           </div>
            <Footer />
        </>
    )
}

export default Projectpage
