import React from 'react'

import { Link } from 'react-router-dom'
import structure from '../images/structure.jpg'
import arch from '../images/archi.jpg'
import build from '../images/building.jpg'
import conc from '../images/conc.png'
import mech from '../images/mechnical.jpg'
import electrical from '../images/civil.jpg'
import pipe from '../images/pipe.jpg'

const Whatwedo = () => {
    return (
        <div>
        <section id="service" className='service pt-2 md:pt-6' >
        <h1 data-aos="fade-down" class="title-font sm:text-4xl text-3xl -mb-4 mt-20 text-center font-medium text-gray-900">What we do
        </h1>
        <ul class="honeycomb">
           <li data-aos="fade-up"  class="honeycomb-cell">
            <img class="honeycomb-cell_image" alt="image" src={`${structure}`} />
            <Link to="/structural" >       <div class="honeycomb-cell_title">STRUCTURAL DESIGN</div></Link>
            </li> 
            <li data-aos="fade-up"  class="honeycomb-cell">
                <img class="honeycomb-cell_image" alt="image" src={`${electrical}`} />
                <Link to="/civil" >    <div class="honeycomb-cell_title">CIVIL DESIGN </div></Link>
            </li>
            <li data-aos="fade-up"  class="honeycomb-cell">
                <img class="honeycomb-cell_image" alt="image" src={`${pipe}`} />
                <Link to="/pipe" >       <div class="honeycomb-cell_title">PIPE DESIGN</div></Link>
            </li>

           
            <li data-aos="fade-up"  class="honeycomb-cell">
                <img class="honeycomb-cell_image" alt="image" src={`${mech}`} />
                <Link to="/mechnical" >      <div class="honeycomb-cell_title">MECHANICAL DESIGN
                </div></Link>
            </li>
            <li data-aos="fade-up"  class="honeycomb-cell">
                <img class="honeycomb-cell_image" alt="image" src={`${build}`} />
                <Link to="/structural/" >   <div class="honeycomb-cell_title">BUILDING INFORMATION MODELING
                    </div></Link>
            </li>

            <li class="honeycomb-cell honeycomb_placeholder">
            </li>
        </ul>
    </section>
        </div>
    )
}

export default Whatwedo
