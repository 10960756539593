import React, { useState, useEffect } from 'react'
import sanityClient from '../client.js'
import  Loader  from './Loader'
import Snap1 from '../images/Snap-1.jpg'
import Snap2 from '../images/Snap-2.jpg'
import Snap3 from '../images/Snap-3.jpg'
import Snap4 from '../images/bg4.jpg'
import Flickity from 'react-flickity-component'

import { Link } from 'react-router-dom'
const LandingPage = () => {
    const flickityOptions = {
        wrapAround: true,
        autoPlay: 5000,
        pauseAutoPlayOnHover: false,
        prevNextButtons: true,
    }



    const [ImageData, setImageData] = useState(null)
 
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "carousel"  ]{
                  title,
                  mainImage{
                     asset->{
                         _id,
                         url
                     },
                     alt
                 },
                 
                 
      }`,
            )
            .then((data) => {
               // console.log("data :" + data)
                setImageData(data)
             
            })
            .catch(console.error)
  
     
    }, [])
  
if (!ImageData) return <><Loader /></>
   
    return (
        <div>
            <Flickity
            options={flickityOptions}
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
      
                style={{ marginTop: "5%", height:"100vh", }}
                className="carousel"
                data-flickity='{ "autoPlay": 5000,"pauseAutoPlayOnHover": false,"wrapAround": true,"prevNextButtons":true }'
            >

            {ImageData && ImageData.map((image, index) => {
                
                    return (
                <div
                    className="carousel-cell"
                    style={{
                        backgroundImage: `url(${image.mainImage.asset.url}), linear-gradient(rgba(0, 0, 0, 0.00), rgba(0, 0, 0, .0))`,
                        backgroundBlendMode: 'overlay',
                        backgroundSize: 'contain',
                        backgroundPosition:' center',
                        backgroundRepeat: 'no-repeat', height:"100vh",

                    }}
                ></div>
                ) })}
              
            </Flickity>




            <section
                className="hero-text1 text-white body-font"
                style={{ fontFamily: "manrope" }}
            >
                <div
                    id="absolute"
                    className=" 
            container
            mx-auto
            flex
            px-5
            py-24
            items-center
            justify-center
            flex-col
            "
                >
                    <div className="text-center mr-8  container   lg:w-4/5 ">
                      
                    <div className="txthvr" style={{background:'#202020',borderRadius:'6px 6px 6px 6px'}}>
                    <h1
                            className="mr-6  
            
            md:mt-40
            pt-4
            -mt-6
pb-2

            "
                            style={{ fontWeight: "normal",fontSize:"4.6vh",borderRadius:'6px 6px 0px 0px' }}
                        >
                            We are a Full Service Detailed Engineering Company
                        </h1>
                        <p
                            className="mb-4 pb-4 mr-6 hero-text2  leading-relaxed"
                            style={{ fontWeight: "normal",fontSize:"2.7vh",borderRadius:'0px 0px 6px 6px' }}
                        >
                            Built on Reputation to Help Clients Get Most Out of Our Experience &
                            Expertise
                        </p>
                        </div>
                      {/**  <div className="flex justify-center">
                         <a href="#contact-us">   <button
                                className="
            inline-flex
            text-white
            knowmore
            border-2
            py-2
            px-6
            text-lg
            "
                            >
                                Know more
                            </button></a>
                        </div> */}
                    </div>
                </div>
            </section >
        </div >
    )
}

export default LandingPage
